









import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import useBtn from '@/composables/use-btn'

export default defineComponent({
  props: {
    size: {
      type: String as PropType<'xs' | 'sm'>,
      default: 'sm',
    },
  },
  setup (props, context) {
    const className = computed(() => ({
      'w-10 h-10': props.size === 'sm',
      'w-7 h-7 text-sm': props.size === 'xs',
    }))
    return {
      ...useBtn(context),
      className,
    }
  },
})
