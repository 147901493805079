import { useContext } from '@nuxtjs/composition-api'

import dayjs, { Dayjs } from 'dayjs'
import zhTw, { Locale } from 'dayjs/locale/zh-tw'
import en from 'dayjs/locale/en'
import ja from 'dayjs/locale/ja'
import localeData from 'dayjs/plugin/localeData'

dayjs.extend(localeData)

export default function () {
  const { i18n } = useContext().app
  const dayjsLocales: Record<string, Locale> = {
    'zh-tw': zhTw,
    en,
    ja,
  }
  /** 根據目前 Nuxt i18n 的語言取得 dayjs 的 locale */
  const currentDayjsLocale = dayjsLocales[i18n.locale]

  /** 本地化的星期字串列表 */
  const weekdays = dayjs().locale(currentDayjsLocale).localeData().weekdaysMin()

  /** 取得本地化的月曆標題（即年月） */
  const getCalendarTitle = (month: Dayjs) => {
    const formats: Record<string, string> = {
      'zh-tw': 'YYYY年MMM',
      en: 'MMMM YYYY',
      ja: 'MMMM YYYY',
    }
    return month
      .locale(currentDayjsLocale)
      .format(formats[i18n.locale ?? 'en'])
  }

  /** 取得本地化的 Dayjs 實例 */
  const getLocaleDayjs = (dayjs: Dayjs) => dayjs.locale(currentDayjsLocale)

  /** 取得當天的日期對使用者來說是否年滿 20 歲 */
  const getUserAge = (birthday: string) => {
    const now = dayjs()
    const birth = dayjs(birthday)
    let age = now.year() - birth.year()
    const month = now.month() - birth.month()
    if (month < 0 || (month === 0 && now.date() < birth.date())) {
      age--
    }
    if (age < 20) return true
    return false
  }

  return {
    currentDayjsLocale,
    weekdays,
    getCalendarTitle,
    getLocaleDayjs,

    getUserAge,
  }
}
