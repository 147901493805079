




















import dayjs, { Dayjs } from 'dayjs'
import useDayjsLocale from '@/composables/use-dayjs-locale'
import isBetween from 'dayjs/plugin/isBetween'

import { defineComponent, computed, PropType } from '@nuxtjs/composition-api'
import { getMonthlyCalendar, getThisMonth, getToday } from '@/modules/calendar'
import DateHour from '@/classes/DateHour'

const { normalizeDate } = DateHour

dayjs.extend(isBetween)

export default defineComponent({
  props: {
    month: {
      type: Dayjs,
      default: getThisMonth(),
    },
    start: {
      type: Dayjs as PropType<Dayjs | null>,
      default: null,
    },
    end: {
      type: Dayjs as PropType<Dayjs | null>,
      default: null,
    },
    disabledBefore: {
      type: Dayjs as PropType<Dayjs | null>,
      default: null,
    },
    disallowedDates: {
      type: [Array, Function, null] as PropType<Dayjs[] | ((date: Dayjs) => boolean) | null>, // 若 callback 回傳為 true, 代表 date 為 disallowed
      default: null,
    },
    allowBeforeToday: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const calendar = computed(() => getMonthlyCalendar(props.month))
    const { weekdays } = useDayjsLocale()

    const select = (date: Dayjs) => {
      emit('select', date)
    }

    const getIsDisabled = (date: Dayjs) => {
      const { disabledBefore, disallowedDates } = props

      let isDisallowedDate = false
      if (Array.isArray(disallowedDates)) {
        isDisallowedDate = disallowedDates.some((disallowedDate) => {
          return normalizeDate(disallowedDate).isSame(normalizeDate(date))
        })
      } else if (disallowedDates !== null) { // 是 function
        isDisallowedDate = disallowedDates(date)
      }

      if (isDisallowedDate) return true
      if (!disabledBefore) return false
      if (props.allowBeforeToday) { return false } else { return date.isBefore(disabledBefore) }
    }

    const getDateClass = (date: Dayjs) => {
      const today = getToday()
      const { start, end } = props

      return {
        start: start && date.isSame(start),
        end: end && date.isSame(end),
        duration:
          (start && end) &&
          date.isBetween(start, end, 'date', '[]') &&
          !start.isSame(end),
        today: today.isSame(date),
      }
    }

    return {
      calendar,
      weekdays,
      select,
      getIsDisabled,
      getDateClass,
    }
  },
})
