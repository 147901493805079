





import { defineComponent, nextTick, ref } from '@nuxtjs/composition-api'
export default defineComponent({
  setup () {
    const el = ref<Element | null>(null)
    const scrollToTop = async () => {
      await nextTick()
      el?.value?.scrollTo(0, 0)
    }

    return {
      el,
      scrollToTop,
    }
  },
})
