import { render, staticRenderFns } from "./DatePickerBody.vue?vue&type=template&id=b07399c2&scoped=true&lang=pug&"
import script from "./DatePickerBody.vue?vue&type=script&lang=ts&"
export * from "./DatePickerBody.vue?vue&type=script&lang=ts&"
import style0 from "./DatePickerBody.vue?vue&type=style&index=0&id=b07399c2&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b07399c2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GBtnIcon: require('/app/components/GBtnIcon.vue').default,DatePickerCalendar: require('/app/components/date-picker/DatePickerCalendar.vue').default,GDialogHeader: require('/app/components/GDialogHeader.vue').default,GDialogBody: require('/app/components/GDialogBody.vue').default,GBtn: require('/app/components/GBtn.vue').default,GDialogFooter: require('/app/components/GDialogFooter.vue').default,GDialogContainer: require('/app/components/GDialogContainer.vue').default})
