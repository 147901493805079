import dayjs, { Dayjs } from 'dayjs'
import objectSupport from 'dayjs/plugin/objectSupport'
import DateHour from '@/classes/DateHour'

dayjs.extend(objectSupport)

/** 取得本「年月」 */
export const getThisMonth = () => dayjs().startOf('month')

/** 取得本日 */
export const getToday = () => DateHour.normalizeDate(dayjs())

/** 取得指定「年月」的月曆 */
export const getMonthlyCalendar = (
  month = getThisMonth(),
) => {
  const calendar: Dayjs[][] = []
  const dayCount = month.daysInMonth() // 該「年月」的總天數

  for (let date = 1, week = 0; date <= dayCount; date++) {
    const dayInstance = month.set({ date }) // 新建此日
    const dayOfWeek = dayInstance.day() // 此日為星期 n (0 ~ 6)

    if (!calendar[week]) {
      calendar.push(Array(7).fill(null)) // 用 null 填滿此新週的每一天
    }

    calendar[week][dayOfWeek] = dayInstance

    if (dayOfWeek === 6) week++ // 換行至下一週
  }
  return calendar
}
